"use strict";

jQuery(document).ready(function ($) {
  // Contact Form * * * * * * * * * * * * * /
  if ($('#webform-client-form-13474').size() > 0) {
    // jump to form
    var target = window.location.hash;

    if ($(target).size() > 0) {
      $(window).on('load', function () {
        if (target) {
          $('html, body').animate({
            scrollTop: $(target).offset().top - 220
          }, 500, 'swing', function () {});
        }
      });
    }
  } // Team Grant Application Form * * * * * * * * * * * * * /


  if ($('#webform-client-form-2460').size() > 0) {
    // jump to form
    var target = window.location.hash;
    $(window).on('load', function () {
      if (target) {
        $('html, body').animate({
          scrollTop: $(target).offset().top - 220
        }, 500, 'swing', function () {});
      }
    }); // max chars

    var clew_form_max_chars = function clew_form_max_chars(textarea, maxChars) {
      $('#webform-client-form-2460 ' + textarea + ' textarea').keydown(function (e) {
        // backspace (8) or delete (46) keys allowed
        if (e.keyCode != 8 && e.keyCode != 46 && $(this).val().length >= maxChars) {
          e.preventDefault();
        }
      }).keyup(function (e) {});
    };

    clew_form_max_chars('#webform-component-pitch', 1000);
    clew_form_max_chars('#webform-component-research-plan', 900);
    clew_form_max_chars('#webform-component-budget-plan', 800);
    clew_form_max_chars('#webform-component-team-member-1--professional-profile--media-outlets', 300);
    clew_form_max_chars('#webform-component-team-member-2--professional-profile--media-outlets', 300);
    clew_form_max_chars('#webform-component-team-member-3--professional-profile--media-outlets', 300);
    clew_form_max_chars('#webform-component-team-member-4--professional-profile--media-outlets', 300);
    clew_form_max_chars('#webform-component-team-member-1--professional-profile--topics', 150);
    clew_form_max_chars('#webform-component-team-member-2--professional-profile--topics', 150);
    clew_form_max_chars('#webform-component-team-member-3--professional-profile--topics', 150);
    clew_form_max_chars('#webform-component-team-member-4--professional-profile--topics', 150);
    $('#webform-component-team-member-1--professional-profile--how-long-type').appendTo($('#webform-component-team-member-1--professional-profile--how-long-number'));
    $('#webform-component-team-member-2--professional-profile--how-long-type').appendTo($('#webform-component-team-member-2--professional-profile--how-long-number'));
    $('#webform-component-team-member-3--professional-profile--how-long-type').appendTo($('#webform-component-team-member-3--professional-profile--how-long-number'));
    $('#webform-component-team-member-4--professional-profile--how-long-type').appendTo($('#webform-component-team-member-4--professional-profile--how-long-number'));
    $('.form-checkbox.error').each(function () {
      $(this).parent().addClass('error');
    });
  } // Join CLEW Journalism Network Form * * * * * * * * * * * * * /


  if ($('#webform-client-form-3660').size() > 0) {
    // max chars
    var _clew_form_max_chars = function clew_form_max_chars(textarea, maxChars) {
      $('#webform-client-form-3660 ' + textarea + ' textarea').keydown(function (e) {
        // backspace (8) or delete (46) keys allowed
        if (e.keyCode != 8 && e.keyCode != 46 && $(this).val().length >= maxChars) {
          e.preventDefault();
        }
      }).keyup(function (e) {});
    };

    _clew_form_max_chars('#webform-component-clew-journalism-network--reasons', 2200);

    $('#webform-component-professional-profile--how-long-type').appendTo($('#webform-component-professional-profile--how-long-number'));
    $('.form-checkbox.error').each(function () {
      $(this).parent().addClass('error');
    });
  } // Study tour / Event application forms * * * * * * * * * * * * * /


  if ($('.m-form--application__event').size() > 0) {
    // max chars
    var _clew_form_max_chars2 = function clew_form_max_chars(textarea, maxChars) {
      $('.m-form--application__event .webform-client-form ' + textarea + ' textarea').keydown(function (e) {
        // backspace (8) or delete (46) keys allowed
        if (e.keyCode != 8 && e.keyCode != 46 && $(this).val().length >= maxChars) {
          e.preventDefault();
        }
      }).keyup(function (e) {});
    };

    _clew_form_max_chars2('#webform-component-professional-profile--media-outlets', 300);

    _clew_form_max_chars2('#webform-component-professional-profile--topics', 150);

    _clew_form_max_chars2('#webform-component-hear-about', 80);

    _clew_form_max_chars2('#webform-component-outlets-for-publication', 100);

    if ($('#webform-component-social-media-channel-00').size() > 0) {
      $(document).on('click', '#webform-component-social-media-channel-00 label', function (e) {
        $('#webform-component-social-media-channel-00').addClass('active');
      });
    }

    if ($('#webform-component-professional-profile--how-long-type').size() > 0) {
      $('#webform-component-professional-profile--how-long-type').appendTo($('#webform-component-professional-profile--how-long-number'));
    }

    $('.form-checkbox.error').each(function () {
      $(this).parent().addClass('error');
    });
  } // Node Forms Title Length * * * * * * * * * * * * * /


  if ($('#article-node-form').size() > 0 || $('#dossier-node-form').size() > 0 || $('#factsheet-node-form').size() > 0 || $('#event-node-form').size() > 0) {
    var clew_form_title_chars = function clew_form_title_chars(field) {
      var maxCharsTitle = 70;

      if (field.parent().find('#form-item-title-length').size() == 0) {
        $('<span id="form-item-title-length"></span>').insertBefore(field.parent().find('label .form-required'));
      }

      var inputTitle = field.val();
      var remainingLengthTitle = maxCharsTitle - inputTitle.length;
      $('#form-item-title-length').text("(".concat(inputTitle.length, ") "));

      if (remainingLengthTitle < 0) {
        field.css({
          'color': 'red',
          'border': '1px solid red'
        });
        $('#form-item-title-length').css('color', 'red');
      } else {
        field.removeAttr('style');
        $('#form-item-title-length').removeAttr('style');
      }
    };

    clew_form_title_chars($('.form-item-title input'));
    $('.form-item-title input').keyup(function (e) {
      clew_form_title_chars($(this));
    });
  }
});